import React, {useState} from 'react'
// import Image from 'gatsby-image'
import {navigate} from 'gatsby-link'
// import {Link} from 'gatsby'
import Recaptcha from 'react-recaptcha'
// import Layout from '../containers/layout'
import PortableText from './portableText'
import Container from './container'

import styles from './page.module.css'

const ContactPage = ({title, _rawBody, thankYou, emailSubject, emailTo}) => {
  function encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // State
  const [formValues, setFormValues] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  // Google Recaptcha
  let recaptchaLoaded = function () {
    // console.log('Loaded')
  }
  let expiredCallback = function () {
    // console.log('expired')
    setIsVerified(false)
    setRecaptchaResponse(null)
  }
  let verifyCallback = function (response) {
    // console.log(response)
    if (response) {
      setIsVerified(true)
      setRecaptchaResponse(response)
    } else {
      setIsVerified(false)
      setRecaptchaResponse(null)
    }
  }

  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }
  const handleSubmit = e => {
    e.preventDefault()
    // console.log({e})

    if (isVerified && formValues.yourFullName && formValues.emailAddress) {
      // console.log('valid form')
      // console.log({formValues})
      setFormErrors(false)

      const form = e.target
      fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: encode({
          'to': emailTo,
          // 'to': 'brent@variantstudios.com',
          'from': 'noreply@champfc.com',
          'subject': emailSubject,
          // 'subject': 'Contact Us',
          ...formValues,
          recaptcha: recaptchaResponse
        })
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => console.error(error))
    } else {
      // console.log('invalid form')
      setFormErrors(true)
    }
  }

  return (
    <>

      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>
              <div style={{paddingRight: '30px', width: '100%'}}>
                {_rawBody && <PortableText blocks={_rawBody} />}
                <div>
                  <form
                    name={'ChampFC.com Contact Form'}
                    method='post'
                    action={thankYou}
                    onSubmit={handleSubmit}
                  >

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'yourFullName'}>
                    Your Full Name *
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'text'}
                          name={'yourFullName'}
                          onChange={handleChange}
                          id={'yourFullName'}
                          required
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'phoneNumber'}>
                    Your Phone Number
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'text'}
                          name={'phoneNumber'}
                          onChange={handleChange}
                          id={'phoneNumber'}
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'emailAddress'}>
                    Your Email Address *
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'email'}
                          name={'emailAddress'}
                          onChange={handleChange}
                          id={'emailAddress'}
                          required
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'coachesName'}>
                      The Name of the Coach You are Nominating *
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'text'}
                          name={'coachesName'}
                          onChange={handleChange}
                          id={'coachesName'}
                          required
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'aboutAndWhy'}>
                      Please tell us about the Coach, where they coach and why they are deserving of this award *
                      </label>
                      <div className='control'>
                        <textarea
                          className={styles.customInput}
                          name={'aboutAndWhy'}
                          onChange={handleChange}
                          id={'aboutAndWhy'}
                          required
                          rows='4'
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'coachsEmailAddress'}>
                      Coach's Email Address
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'email'}
                          name={'coachsEmail'}
                          onChange={handleChange}
                          id={'coachsEmail'}
                        />
                      </div>
                    </div>

                    <div className='field'>
                      <label className={styles.customLabel} htmlFor={'coachsPhoneNumber'}>
                      Coach's Phone Number
                      </label>
                      <div className='control'>
                        <input
                          className={styles.customInput}
                          type={'text'}
                          name={'coachsPhoneNumber'}
                          onChange={handleChange}
                          id={'coachsPhoneNumber'}
                        />
                      </div>
                    </div>

                    <div>
                      <br />

                      <Recaptcha
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
                        render='explicit'
                        verifyCallback={verifyCallback}
                        onloadCallback={recaptchaLoaded}
                        expiredCallback={expiredCallback}
                      />
                    </div>

                    <div className='field'>
                      <button disabled={formValues.yourFullName && formValues.emailAddress ? false : 'disabled'} className={styles.customButton} type='submit' aria-label='Submit form'>
                    Send
                      </button>
                    </div>
                    {formErrors && (
                      <div className={styles.errors}><p>Unable to submit form. Please make sure all of your fields are filled out.</p></div>
                    )}

                  </form>
                </div>
              </div>

            </div>
          </div>
        </Container>
      </article>

    </>
  )
}
export default ContactPage
